import React, { useEffect, useState, useLayoutEffect } from "react"
import { Button, Modal } from "antd"

import _ from "underscore"
import moment from "moment/moment"

import ImplementationImage from "../../assets/images/Implementation_Dashboard_Icon.png"
import SustainabilityImage from "../../assets/images/Sustainability_Dashboard_Icon.png"
import BadgeImage from "../../assets/images/dashboard-badge.png"
import MatrixImage from "../../assets/images/Matrix_Dashboard_Icon.png"
import OverallImage from "../../assets/images/dashboard-overall.png"
import {
  uaDarkBlue, uaGold, uaRed, uaTeal,
} from "../enums"
import ProgressBar from "./charts/ProgressBar"
import commonStrings from "../commonStrings"
import Loader from "./CommonComponents/Loader"
import { removeParamProgramIdFromURL } from "../utilities"
import ReleaseVideo from "./ReleaseVideo"

const Dashboard = ({
  implementationProgress,
  sustainabilityProgress,
  matrixHref,
  implementationHref,
  sustainabilityHref,
  certified,
  sustainabilityRubrics,
  implementationRubrics,
  matrix,
  currentUserSchools,
  currentUser,
  totalSchools,
  latestReleaseNotes,
}) => {
  const [newCompetencyArray, setNewComptencyArray] = useState([])
  const [newMatrixScoreHash, setNewMatrixScoreHash] = useState([])
  const [indicatorCount, setIndicatorCount] = useState(0)
  const [loader, setLoader] = useState(true)

  useEffect(() => {
    $("[data-toggle=\"tooltip\"]").tooltip()
  }, [])

  function totalSelStandards(name) {
    const newArray = window.gon.SEL_STANDARDS.map((e) => {
      if (e.name === name) {
        return Object.keys(e.standards).length
      }
      return null
    })
    return newArray
  }

  function containsSchool(inputString) {
    const lowercaseInput = inputString.toLowerCase()

    if (lowercaseInput.includes("school")) {
      return true
    }
    return false
  }

  function containsProgram(inputString) {
    const lowercaseInput = inputString.toLowerCase()

    if (lowercaseInput.includes("program")) {
      return true
    }
    return false
  }

  function getSchoolIdFromUrl() {
    const urlParams = new URLSearchParams(window.location.search)
    const schoolId = urlParams.get("school_id")
    return schoolId
  }

  function isNeedReset(id, array) {
    const arrayOfObjects = _.isNull(array) ? [] : array
    for (let i = 0; i < arrayOfObjects.length; i += 1) {
      if (arrayOfObjects[i].id === id) {
        return false
      }
    }
    return true
  }

  const trackUpdateInUserSchools = async () => {
    let isRecordPresent = false

    currentUserSchools.forEach((school) => {
      if (school.id === parseInt(getSchoolIdFromUrl(), 10)) {
        isRecordPresent = true
      }
    })

    if (_.isNull(getSchoolIdFromUrl()) || !isRecordPresent) {
      const newSchoolId = _.first(currentUserSchools).id
      const newUrl = _.isNull(getSchoolIdFromUrl())
        ? `${window.location.href}?school_id=${newSchoolId}`
        : window.location.href.replace(/(school_id=)\d+/, `$1${newSchoolId}`)

      try {
        setLoader(true)

        await new Promise((resolve) => setTimeout(resolve, 1000))

        window.location.href = removeParamProgramIdFromURL(newUrl)
      } catch (error) {
        console.error("Error navigating:", error)
      }
    } else {
      setLoader(false)
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      if (containsSchool(currentUser.role) && currentUserSchools.length > 0) {
        trackUpdateInUserSchools()
      } else {
        setLoader(false)
      }
    }

    fetchData()
  }, [])

  const calculateMatrixScore = (category) => {
    let count = 0
    const filteredArray = matrix
      && matrix.completed_items.filter((x) => x.tier_of_support === "Universal")
    const array = !_.isEmpty(filteredArray)
      && filteredArray.map((e) => Object.keys(e.sel_standards))
    window.gon.SEL_STANDARDS.forEach((e) => {
      if (e.name === category) {
        Object.keys(e.standards).forEach((e) => {
          _.uniq(!_.isEmpty(array) && array.flat()).forEach((value) => {
            if (e === value) {
              count += 1
            }
          })
        })
      }
    })
    return count
  }

  function sumIndicatorCount(array) {
    let total = 0
    for (let i = 0; i < array.length; i += 1) {
      total += array[i].indicator_count
    }
    return total
  }

  function createNewMatrixScoreHash(array) {
    const matrixScoreHash = []

    for (let i = 0; i < array.length; i += 1) {
      const competency = array[i]
      matrixScoreHash.push({
        name: competency.competency_name,
        score: calculateMatrixScore(competency.competency_name),
        totalIndicators: totalSelStandards(competency.competency_name),
      })
    }

    return matrixScoreHash
  }
  const makeCompetencyArray = (arr) => {
    const result = []
    arr.forEach((obj) => {
      const competency = {
        competency_name: obj.name,
        indicator_count: Object.keys(obj.standards).length,
      }
      result.push(competency)
    })
    return result
  }

  useEffect(() => {
    if (!_.isEmpty(window.gon.SEL_STANDARDS)) {
      setNewComptencyArray(makeCompetencyArray(window.gon.SEL_STANDARDS))
    }
  }, [window.gon.SEL_STANDARDS])

  useEffect(() => {
    if (!_.isEmpty(newCompetencyArray)) {
      setNewMatrixScoreHash(createNewMatrixScoreHash(newCompetencyArray))
      setIndicatorCount(sumIndicatorCount(newCompetencyArray))
    }
  }, [newCompetencyArray])
  const calculateTotalIndicators = () => {
    const filteredArray = matrix
      && matrix.completed_items.filter((x) => x.tier_of_support === "Universal")
    const array = !_.isEmpty(filteredArray)
      && filteredArray.map((e) => Object.keys(e.sel_standards))

    return _.unique(!_.isEmpty(array) && array.flat()).length
  }

  const calculateSustainabilityScore = () => {
    let count = 0
    if (!_.isEmpty(sustainabilityRubrics)) {
      _.first(sustainabilityRubrics).indicators.forEach((e) => {
        if (e.rating !== null) {
          count += 1
        }
      })
    }
    return count
  }
  const calculateScore = (name) => {
    let ratings = 0
    if (!_.isEmpty(implementationRubrics)) {
      _.first(implementationRubrics).indicators.forEach((e) => {
        if (e.stage === name) {
          ratings += e.rating
        }
      })
    }

    return ratings
  }
  const totalIndicators = (name) => {
    let score = 0
    if (!_.isEmpty(implementationRubrics)) {
      _.first(implementationRubrics).indicators.forEach((e) => {
        if (e.stage === name) {
          score += 1
        }
      })
    }
    return score * 4
  }

  // const matrixScoreHash = [
  //   {
  //     name: "Self-Awareness (I am)",
  //     score: calculateMatrixScore("Self-Awareness (I am)"),
  //     totalIndicators: totalSelStandards("Self-Awareness (I am)"),
  //   },
  //   {
  //     name: "Self-Management (I can)",
  //     score: calculateMatrixScore("Self-Management (I can)"),
  //     totalIndicators: totalSelStandards("Self-Management (I can)"),
  //   },
  //   {
  //     name: "Social Awareness (I care)",
  //     score: calculateMatrixScore("Social Awareness (I care)"),
  //     totalIndicators: totalSelStandards("Social Awareness (I care)"),
  //   },
  //   {
  //     name: "Social Management (I will)",
  //     score: calculateMatrixScore("Social Management (I will)"),
  //     totalIndicators: totalSelStandards("Social Management (I will)"),
  //   },
  // ];

  const ImplementationScoreHash = [
    {
      name: "Commitment Stage I",
      score: calculateScore("Commitment Stage I"),
      totalIndicators: totalIndicators("Commitment Stage I"),
    },
    {
      name: "Commitment Stage II",
      score: calculateScore("Commitment Stage II"),
      totalIndicators: totalIndicators("Commitment Stage II"),
    },
    {
      name: "Implementation Stage I",
      score: calculateScore("Implementation Stage I"),
      totalIndicators: totalIndicators("Implementation Stage I"),
    },
    {
      name: "Implementation Stage II",
      score: calculateScore("Implementation Stage II"),
      totalIndicators: totalIndicators("Implementation Stage II"),
    },
  ]
  const sustainabilityScoreHash = [
    {
      name: "Sustainability Items Submitted",
      score: calculateSustainabilityScore(),
      totalIndicators:
        !_.isEmpty(sustainabilityRubrics)
        && _.first(sustainabilityRubrics).indicators.length,
    },
  ]

  const overallProgress = Math.round(
    ((calculateTotalIndicators() / indicatorCount) * 100
      + implementationProgress
      + parseInt(
        _.first(
          sustainabilityScoreHash
            .map((e) => (e.score / e.totalIndicators) * 100 || 0)
            .map((p) => p.toFixed(0)),
        ),
        10,
      ))
      / 3,
  )

  const logoutUser = () => {
    const csrfToken = document.querySelector("[name=csrf-token]").content
    fetch("/users/sign_out", {
      method: "DELETE",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.status === 204) {
        window.location.href = "/users/sign_in"
      }
    })
  }
  useLayoutEffect(() => {
    if (containsProgram(currentUser.role)) {
      if (isNeedReset(parseInt(getSchoolIdFromUrl(), 10), totalSchools)) {
        window.location.href = "/"
      }
    }
  }, [])

  return (
    <>
      {!loader ? (
        !(
          containsSchool(currentUser.role) && currentUserSchools.length === 0
        ) && (
          <>
            {!_.isEmpty(latestReleaseNotes)
              && currentUser.release_notes
              && (currentUser.role === "school user"
                || currentUser.role === "school admin") && (
                <div>
                  <ReleaseVideo
                    currentUser={currentUser}
                    latestReleaseNotes={latestReleaseNotes}
                  />
                </div>
            )}

            <div className="row mb-5 mt-5">
              <Widget
                indicatorsCount={calculateTotalIndicators()}
                indicatorCount={indicatorCount}
                btnColor="#0CA4A5"
                title={commonStrings.titleMatrix}
                submissionText={commonStrings.lastUpdatedWidget}
                updatedAt={matrix && matrix.updated_at}
                percentageText={commonStrings.universalActivities}
                description={commonStrings.organizeActivities}
                src={MatrixImage}
                color={uaTeal}
                href={matrixHref}
                progress={(calculateTotalIndicators() / indicatorCount) * 100}
                dataHash={newMatrixScoreHash}
                tooltip={`Average of SEL indicators with universal activities for students: ${(
                  (calculateTotalIndicators() / indicatorCount)
                  * 100
                ).toFixed(0)}%`}
              />
              <Widget
                indicatorsCount={0}
                indicatorCount={indicatorCount}
                dataHash={ImplementationScoreHash}
                btnColor="#E7A922"
                updatedAt={
                  !_.isEmpty(implementationRubrics)
                  && _.first(implementationRubrics).updated_at
                }
                title={commonStrings.titleImplementation}
                submissionText={commonStrings.lastSubmittedWidget}
                percentageText={commonStrings.percentageImplementation}
                description={commonStrings.executeUpdateRubric}
                src={ImplementationImage}
                color={uaGold}
                href={implementationHref}
                progress={implementationProgress}
                tooltip={`Last submitted rubric score: ${implementationProgress.toFixed(
                  0,
                )}%`}
              />
              <Widget
                indicatorsCount={0}
                indicatorCount={indicatorCount}
                btnColor="#33485D"
                updatedAt={
                  !_.isEmpty(sustainabilityRubrics)
                  && _.first(sustainabilityRubrics).updated_at
                }
                title={commonStrings.titleSustainability}
                submissionText={commonStrings.lastSubmittedWidget}
                percentageText={commonStrings.sustainabilityRubricSubmitted}
                description={commonStrings.codifySolutionsStrengthen}
                src={SustainabilityImage}
                color={uaDarkBlue}
                href={sustainabilityHref}
                progress={sustainabilityProgress}
                dataHash={sustainabilityScoreHash}
                tooltip={`Last submitted rubric score: ${sustainabilityScoreHash
                  .map((e) => (e.score / e.totalIndicators) * 100 || 0)
                  .map((p) => p.toFixed(0))}%`}
              />
            </div>
            <div className="row mb-4 justify-content-around">
              <div className="col-4 text-center d-flex align-items-center justify-content-center flex-column">
                <img src={OverallImage} height="60px" className="mb-4" />
                <div className="mb-2 px-5">
                  <ProgressBar
                    color={uaRed}
                    progress={overallProgress}
                    tooltip={`Average of the three SEL rubrics: ${overallProgress.toFixed(
                      0,
                    )}%`}
                  />
                </div>
                <h4 className="font-weight-500 ">
                  {commonStrings.qualityMeter}
                </h4>
              </div>
              <div className="col-4 text-center d-flex justify-content-center align-items-center">
                <span className="text-ua-gray mx-2">
                  {commonStrings.brandonFrame}
                </span>

                <img
                  src={BadgeImage}
                  height="100px"
                  className="mb-3"
                  style={{ opacity: certified ? "1" : "0.2" }}
                  title={
                    certified ? null : commonStrings.reachBrandonFrameCertified
                  }
                  data-toggle="tooltip"
                  data-placement="bottom"
                />
              </div>
            </div>
          </>
        )
      ) : (
        <Loader />
      )}

      {containsSchool(currentUser.role) && currentUserSchools.length === 0 && (
        <Modal
          width={650}
          title={<div className="noSchoolModal">Alert!</div>}
          open
          closable={false}
          footer={[
            <Button
              className="noSchoolModalButton"
              onClick={() => {
                logoutUser()
              }}
            >
              Logout
            </Button>,
          ]}
          centered
        >
          <div>
            <span className="noSchoolModalBody">
              {commonStrings.noSchoolString}{" "}
              <a href="mailto:rsptech@urbanassembly.org">
                rsptech@urbanassembly.org
              </a>
            </span>
          </div>
        </Modal>
      )}
    </>
  )
}

function Widget({
  title,
  description,
  src,
  color,
  href,
  progress,
  tooltip,
  btnColor,
  dataHash,
  percentageText,
  indicatorsCount,
  updatedAt,
  submissionText,
  indicatorCount,
}) {
  const [isClicked, setIsClicked] = useState(false)

  const handleClick = () => {
    setIsClicked(true)
  }

  return (
    <div className="col-12 d-flex align-items-center mb-5">
      <div className="col-4 d-flex align-items-center">
        <img src={src} height="95px" className="mx-auto d-block" />
        <div className="d-flex flex-column">
          <h3 style={{ color: `rgb(${color})` }} className="dashboard_title">
            {title}
          </h3>
          <span className="description_text">{description}</span>
        </div>
      </div>
      <div className="col-3 d-flex justify-content-center align-items-center">
        <div className="d-flex flex-column">
          <span className="last_submitted">
            {updatedAt ? (
              <span>
                {submissionText}: {moment(updatedAt).format("MM/DD/YYYY")}
              </span>
            ) : (
              ""
            )}
          </span>
          <div className="d-flex justify-content-center align-items-center col-12">
            <ProgressBar
              progress={
                title === "Sustainability"
                  ? dataHash
                    .map((e) => (e.score / e.totalIndicators) * 100 || 0)
                    .map((p) => p.toFixed(0))
                  : progress
              }
              color={color}
              tooltip={tooltip}
            />
            <span
              className="mx-2 bottom_text_number"
              style={{ color: `${btnColor}` }}
            >
              {title === "Sustainability"
                ? dataHash
                  .map((e) => (e.score / e.totalIndicators) * 100 || 0)
                  .map((p) => p.toFixed(0))
                : progress.toFixed(0)}
              %
            </span>
          </div>
          {title === "Matrix" && (
            <span
              className="align-self-center bottom_text_number col-8"
              style={{ color: `${btnColor}` }}
            >
              {indicatorsCount}/{indicatorCount}
            </span>
          )}
          <span
            className="align-self-center bottom_text col-8"
            style={{ paddingInline: "13px" }}
          >
            {percentageText}
          </span>
        </div>
      </div>
      <div className="col-5 d-flex align-items-start justify-content-evenly flex-wrap responsive-alignment">
        {dataHash.map((e) => (
          <span
            className="col-5 col-md-6 ratio_status d-flex "
            style={{
              minWidth: `${
                e.name === "Sustainability Items Submitted" && "240px"
              }`,
            }}
          >
            {e.name}: &nbsp;{" "}
            <span style={{ color: `${btnColor}` }}>
              {" "}
              {e.score}/{e.totalIndicators || 0}{" "}
            </span>
          </span>
        ))}
      </div>
      <div className="col-3 align-items-start">
        <>
          {!isClicked && (
            <a
              href={href}
              onClick={handleClick}
              style={{ background: `${btnColor}` }}
              className="btn btn-outline-secondary addBtnStyling"
            >
              View/Add to <br />
              {title}
            </a>
          )}
          {isClicked && (
            <a
              onClick={handleClick}
              style={{ background: `${btnColor}` }}
              className="btn btn-outline-secondary addBtnStyling"
            >
              View/Add to <br />
              {title}
            </a>
          )}
        </>
      </div>
    </div>
  )
}

export default Dashboard
