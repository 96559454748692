import React, { useEffect, useState } from "react"
import _ from "underscore"
// import pluralize from "pluralize"
import { CircularProgressbar, buildStyles } from "react-circular-progressbar"
import { PDFDownloadLink } from "@react-pdf/renderer"
import html2canvas from "html2canvas"
import { Drawer, Collapse } from "antd"
import {
  // infoAlert,
  formatDate,
  getStageCounts,
  graphLabelObject,
  removeDuplicateMonths,
  // totalBarIndex,
} from "../../utilities"
import "react-circular-progressbar/dist/styles.css"
import commonStrings from "../../commonStrings"
import pdfSvg from "../../../assets/images/pdf-file.svg"
import ImplementationReportPdf from "./ImplementationReportPdf"
import Loader from "../CommonComponents/Loader"
import styles from "../../styles"
import CustomBarChart from "../charts/OldBarChart"
import BarChart from "../charts/OldChart"
import DrawerOldRecords from "./DrawerOldRecords"

const ImplementationReport = ({
  rubric,
  generalColor,
  previousRubricUpdateDate,
  rubricNumber,
  currentschoolLogo,
  currentSchoolName,
  dateForReport,
  reportDate,
  rubricType,
  rubricListing,
}) => {
  console.log(rubric, "TEST")
  const [releaseData] = useState("2024-08-01T00:00:00.000Z")
  const [getElementById, setGetElementById] = useState(null)
  const [graphUrlBase64, setGraphUrlBase64] = useState("")
  const [dataSetsGraph, setDataSetsGraph] = useState([])
  const [dataSetsLinerGraph, setDataSetsLinerGraph] = useState([])
  const [selectedDate, setSelectedDate] = useState(null)
  const [activeKey, setActiveKey] = useState([])
  const [isDrawerVisible, setIsDrawerVisible] = useState(false)
  const [selectedMonthData, setSelectedMonthData] = useState(null)
  const [, setSelectedDateindicator] = useState(null)
  const [selectedMonthRatings, setSelectedMonthRatings] = useState({})
  const [isShowPreviousData, setIsShowPreviousData] = useState(false)
  const [countStages] = useState(getStageCounts(rubric.indicators))
  const stages = [
    { key: "1", title: "Commitment Stage I", color: "rgb(166, 25, 46)" },
    { key: "2", title: "Commitment Stage II", color: "rgb(51, 72, 93)" },
    { key: "3", title: "Implementation Stage I", color: "rgb(231, 169, 34)" },
    { key: "4", title: "Implementation Stage II", color: "rgb(12, 164, 165)" },
  ]
  const currentRubricIndex = rubricListing
    .filter((obj) => obj.submitted_at !== null)
    .findIndex((r) => r.id === rubric.id)

  function addConfigurationsOfGraph(formattedRubricArray) {
    const datasets = formattedRubricArray.map((formattedRubric, index) => {
      if (
        formattedRubric
        && typeof formattedRubric === "object"
        && Object.keys(formattedRubric).length > 0
      ) {
        const dateKey = Object.keys(formattedRubric)[0]
        const rubricData = formattedRubric[dateKey]

        const monthYearPattern = /^[A-Za-z]{3} \d{4}$/
        const label = monthYearPattern.test(dateKey) ? dateKey : "Invalid date"

        const hasPreviousRubric = currentRubricIndex > 0

        const colors = index === 0 && hasPreviousRubric
          ? "#CBCACB"
          : ["#A6192E", "#33475C", "#E8A923", "#0CA6A6", "#7D7B7C"]

        const borderColor = index === 0 && hasPreviousRubric
          ? "#CBCACB"
          : ["#A6192E", "#33475C", "#E8A923", "#0CA6A6", "#7D7B7C"]

        return {
          label,
          data: _.values(rubricData),
          backgroundColor: colors,
          borderColor,
          borderWidth: 1,
        }
      }
      return {}
    })

    return datasets
  }
  function reorderResultsKeys(inputArray) {
    const outputArray = inputArray.map((obj) => {
      const reorderedResults = {}
      const orderedKeys = [
        "Commitment Stage I",
        "Commitment Stage II",
        "Implementation Stage I",
        "Implementation Stage II",
        "Overall",
      ]

      orderedKeys.forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(obj.results, key)) {
          reorderedResults[key] = obj.results[key]
        }
      })

      const newObj = { ...obj, results: reorderedResults }
      return newObj
    })

    return outputArray
  }

  function filterObjectsWithSubmittedAt(array) {
    const filteredArray = array.filter((obj) => obj.submitted_at !== null)
    return reorderResultsKeys(filteredArray)
  }

  const formatMonthYear = (dateStr) => {
    const date = new Date(dateStr)
    const options = { month: "short", year: "numeric" }
    return new Intl.DateTimeFormat("en-US", options).format(date)
  }

  const findAndReturnPrevious = (object, arrayOfObjects) => {
    const currentIndex = arrayOfObjects.findIndex(
      (item) => item.id === object.id,
    )

    if (currentIndex !== -1 && currentIndex > 0) {
      const currentObj = arrayOfObjects[currentIndex]
      const previousObj = arrayOfObjects[currentIndex - 1]

      const currentKey = formatMonthYear(currentObj.updated_at)
      const previousKey = formatMonthYear(previousObj.updated_at)

      return [
        { [previousKey]: previousObj.results },
        { [currentKey]: currentObj.results },
      ]
    }
    if (currentIndex !== -1) {
      const currentObj = arrayOfObjects[currentIndex]
      const currentKey = formatMonthYear(currentObj.updated_at)

      return [{ [currentKey]: currentObj.results }]
    }
    return []
  }

  function useFormattedRubrics(rubric, rubricListing) {
    return findAndReturnPrevious(
      rubric,
      filterObjectsWithSubmittedAt(rubricListing),
    )
  }

  // const overallBarClickText = () => {
  //   const score = rubric.report.find(
  //     (stage) => stage.label === "Overall",
  //   ).completion
  //   return score >= 90
  //     ? "Congratulations, you met the 90% goal!"
  //     : `You’re only ${90 - score} ${pluralize(
  //       "points",
  //       90 - score,
  //     )} away from the 90% goal!`
  // }

  // const onBarChartClick = (barIndex) => {
  const onBarChartClick = () => {
    // if (barIndex === totalBarIndex.overall) infoAlert({ title: overallBarClickText() })
    // else {
    //   const element = document.getElementById(`report-section-${barIndex}`)
    //   if (element) {
    //     element.scrollIntoView()
    //   }
    // }
    setIsShowPreviousData(true)
    setIsDrawerVisible(true)
  }

  setTimeout(() => {
    setGetElementById(document.getElementById(commonStrings.pdfContent))
  }, 1000)

  useEffect(() => {
    if (!_.isNull(getElementById)) {
      html2canvas(getElementById, {
        scale: 5,
      }).then((canvas) => {
        const graphImage = canvas.toDataURL("image/png")
        setGraphUrlBase64(graphImage)
      })
    }
  }, [getElementById])

  useEffect(() => {
    setDataSetsGraph(
      addConfigurationsOfGraph(useFormattedRubrics(rubric, rubricListing)),
    )
  }, [rubric, rubricListing])

  const labels = _.map(rubric.report, (stage) => stage.label)
  const formatMonthYearforLinear = (dateStr) => {
    const [year, month] = dateStr.split("-").slice(0, 2)
    return new Date(year, month - 1, 1).toLocaleString("en-us", {
      month: "long",
    })
  }
  const onBarChartClickforLinear = (clickedIndex) => {
    const monthLabel = graphLabelObject[clickedIndex]
    const clickedDate = graphLabelObject[clickedIndex]
    setSelectedDate(clickedDate)
    setSelectedDateindicator(clickedDate)

    const detailedResultsForMonth = removeDuplicateMonths(
      rubric.detailed_result,
    ).filter((result) => formatMonthYearforLinear(result.updated_at).includes(monthLabel))

    if (detailedResultsForMonth.length > 0) {
      const latestResult = detailedResultsForMonth.reduce((a, b) => (new Date(a.updated_at) > new Date(b.updated_at) ? a : b))
      setSelectedMonthData(latestResult.results)
      setIsDrawerVisible(true)
    } else {
      setSelectedMonthData(null)
      setIsDrawerVisible(true)
    }

    const detailedResultsForMonthindicator = rubric.rubric_update_history.filter((result) => formatMonthYearforLinear(result.updated_at).includes(monthLabel))

    if (detailedResultsForMonthindicator.length > 0) {
      setIsDrawerVisible(true)
    } else {
      setIsDrawerVisible(true)
    }
    if (detailedResultsForMonthindicator.length > 0) {
      const ratings = {}
      detailedResultsForMonthindicator.forEach((result) => {
        ratings[result.index] = result.rating
      })
      setSelectedMonthRatings(ratings)
      setIsDrawerVisible(true)
    } else {
      setSelectedMonthRatings({})
      setIsDrawerVisible(true)
    }
    const latestMonths = {}
    rubric.rubric_update_history.forEach((result) => {
      const { stage } = result
      const month = formatMonthYearforLinear(result.updated_at)
      if (
        !(stage in latestMonths)
        || new Date(month) > new Date(latestMonths[stage])
      ) {
        latestMonths[stage] = month
      }
    })
  }
  const closeDrawer = () => {
    setIsDrawerVisible(false)
  }

  useEffect(() => {
    const updatedRatings = { ...selectedMonthRatings }
    rubric.rubric_update_history.forEach((item) => {
      if (formatMonthYearforLinear(item.updated_at) === selectedDate) {
        updatedRatings[item.index] = item.rating
      }
    })
  }, [rubric.rubric_update_history, selectedDate, selectedMonthRatings])

  const processRubricData = (rubricData, monthLabels) => {
    if (
      !rubricData
      || !rubricData.detailed_result
      || rubricData.detailed_result.length === 0
      || !monthLabels
    ) {
      return []
    }

    const resultsByMonth = rubricData.detailed_result.reduce((acc, result) => {
      const month = formatMonthYearforLinear(result.updated_at)
      return {
        ...acc,
        [month]: acc[month] ? [...acc[month], result] : [result],
      }
    }, {})

    const dataPoints = monthLabels.map((month) => {
      const results = resultsByMonth[month]
      if (results && results.length > 0) {
        const latestResult = results.reduce(
          (latest, result) => (new Date(result.updated_at) > new Date(latest.updated_at)
            ? result
            : latest),
          results[0],
        )
        return latestResult.results.Overall || 0
      }
      return 0
    })

    const dataSet = {
      label: "Overall",
      data: dataPoints,
      backgroundColor: "#CBCACB",
      borderWidth: 1,
      spanGaps: false,
      fill: false,
    }

    return [dataSet]
  }

  useEffect(() => {
    setDataSetsLinerGraph(processRubricData(rubric, graphLabelObject))
  }, [rubric])
  const updatedDatasets = dataSetsLinerGraph.map((dataset, index) => {
    const backgroundColor = Array(graphLabelObject.length).fill("#CBCACB")

    const validResults = rubric.detailed_result.filter(
      (result) => result.results && Object.keys(result.results).length > 0,
    )

    const mostRecentUpdate = validResults.reduce(
      (latest, result) => (new Date(result.updated_at) > new Date(latest.updated_at)
        ? result
        : latest),
      _.first(validResults),
    )

    const mostRecentMonthIndex = graphLabelObject.findIndex(
      (month) => month === formatMonthYearforLinear(mostRecentUpdate.updated_at),
    )

    if (index === 0 && mostRecentMonthIndex !== -1) {
      backgroundColor[mostRecentMonthIndex] = "#a6192e"
    }

    const monthResultsMap = validResults.reduce((acc, result) => {
      const monthYear = formatMonthYearforLinear(result.updated_at)
      acc[monthYear] = result.results
      return acc
    }, {})

    const stageDataArray = []

    if (Object.keys(monthResultsMap).length > 0) {
      Object.keys(monthResultsMap[Object.keys(monthResultsMap)[0]]).forEach(
        (stageName) => {
          const stageData = graphLabelObject.map((month) => ({
            month,
            [stageName]: monthResultsMap[month]
              ? monthResultsMap[month][stageName]
              : null,
          }))

          stageDataArray.push({ stageName, data: stageData })
        },
      )
    }

    return {
      ...dataset,
      backgroundColor,
      data: stageDataArray,
    }
  })

  useEffect(() => {
    if (selectedMonthData) {
      const filteredKeys = Object.keys(selectedMonthData).filter(
        (key) => key !== "Overall",
      )
      setActiveKey(filteredKeys)
    }
  }, [selectedMonthData])

  const handleCollapseChange = (keys) => {
    setActiveKey(keys)
  }
  const { Panel } = Collapse

  const getPanelStyle = (stageName) => {
    const stageStyles = {
      "commitment stage i": { backgroundColor: "rgb(166, 25, 46)" },
      "commitment stage ii": { backgroundColor: "rgb(51, 72, 93)" },
      "implementation stage i": { backgroundColor: "rgb(231, 169, 34)" },
      "implementation stage ii": { backgroundColor: "rgb(12, 164, 165)" },
    }

    const lowerCase = stageName.toLowerCase()
    return stageStyles[lowerCase] || {}
  }
  const PanelHeader = ({ stageName, stageValue }) => (
    <div style={styles.stageNameStyling}>
      <div className="stageName">{`${stageName} `}</div>
      <div style={styles.stageValueStyling}>{stageValue || 0}</div>
    </div>
  )
  const months = [
    "August",
    "September",
    "October",
    "November",
    "December",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
  ]
  const processMonthlyData = (rubricHistory) => {
    const dataByMonth = months.reduce((acc, month) => {
      acc[month] = []
      return acc
    }, {})

    let carryOverRatings = {}

    rubricHistory.forEach((item) => {
      const month = new Date(item.created_at).toLocaleString("default", {
        month: "long",
      })

      if (dataByMonth[month]) {
        dataByMonth[month].push(item)
      } else {
        dataByMonth[month] = [item]
      }

      if (item.previously_submitted === true) {
        carryOverRatings = {
          ...carryOverRatings,
          ...item.carryOverRatings,
        }
      } else {
        carryOverRatings[item.index] = {
          rating: item.rating,
          stage: item.stage,
        }
      }
    })

    const processedData = months.map((month, monthIndex) => {
      const monthData = months
        .slice(0, monthIndex + 1)
        .map((currentMonth) => dataByMonth[currentMonth] || [])
        .flat()

      const previouslySubmittedTrue = monthData.some(
        (item) => item.previously_submitted === true,
      )

      if (previouslySubmittedTrue) {
        const accumulatedPreviousData = months
          .slice(0, monthIndex)
          .map((month) => (dataByMonth[month].some(
            (item) => item.previously_submitted === null,
          )
            ? dataByMonth[month]
            : []))
          .flat()

        const updatedMonthData = [...accumulatedPreviousData, ...monthData]

        updatedMonthData.forEach((item) => {
          item.carryOverRatings = {
            ...carryOverRatings,
            ...item.carryOverRatings,
          }
        })
        carryOverRatings = {}
      }

      return {
        month,
        data: monthData,
      }
    })

    return processedData
  }

  const processedData = processMonthlyData(rubric.rubric_update_history)

  console.log(previousRubricUpdateDate)
  return (
    <div>
      <div className="newPdfButton">
        <div style={styles.minHeightLoaderDiv}>
          {graphUrlBase64.length === 0 ? (
            <div>
              <Loader />
            </div>
          ) : (
            <PDFDownloadLink
              document={(
                <ImplementationReportPdf
                  rubric={rubric}
                  rubricNumber={rubricNumber}
                  graphUrlBase64={graphUrlBase64}
                  currentschoolLogo={currentschoolLogo}
                  generalColor={generalColor}
                  rubricType={rubricType}
                />
              )}
              fileName={`${
                currentSchoolName || "school_name"
              }_report(${dateForReport}).pdf`}
            >
              {({ loading }) => (loading ? (
                <Loader />
              ) : (
                <img style={{ width: "40px" }} src={pdfSvg} />
              ))}
            </PDFDownloadLink>
          )}
        </div>
      </div>
      <div className="report p-4 mb-4">
        <div id="pdfContent" className="paddingLR24">
          {!_.isNull(previousRubricUpdateDate.updated_at)
            && !(releaseData <= rubric.created_at) && (
              <div className="d-flex align-items-center paddingL35">
                {rubric.detailed_result
                && rubric.detailed_result.length > 0 ? null : (
                  <>
                    <div className="greyBox" />
                    <div className="px-2">
                      = last updated rubric score on{" "}
                      {formatDate(previousRubricUpdateDate.updated_at)}
                    </div>
                  </>
                  )}
              </div>
          )}
          {(rubric.detailed_result
            && rubric.detailed_result.length > 0
            && rubric.rubric_update_history.length > 0)
          || releaseData <= rubric.created_at ? (
            <CustomBarChart
              labels={graphLabelObject}
              datasets={updatedDatasets}
              max={100}
              mb={5}
              onClick={onBarChartClickforLinear}
            />
            ) : (
              <BarChart
                labels={labels}
                datasets={dataSetsGraph}
                max={100}
                mb={5}
                onClick={onBarChartClick}
                formatPercentage
                graphUrlBase64={graphUrlBase64}
                previousRubricUpdateDate={previousRubricUpdateDate.updated_at}
                reportDate={reportDate}
              />
            )}
          <Drawer
            title={commonStrings.implementationDetails}
            placement={commonStrings.left}
            closable
            onClose={closeDrawer}
            visible={isDrawerVisible}
            destroyOnClose
            mask
            maskClosable
            className="implementation-graph-drawer"
          >
            {!isShowPreviousData && selectedMonthData ? (
              <>
                <div className="overall-styling">
                  {`Overall: ${selectedMonthData.Overall || 0}%`}
                </div>

                <div className="date-display">
                  {selectedDate || commonStrings.noDateSelected}
                </div>
                <Collapse
                  activeKey={activeKey}
                  onChange={handleCollapseChange}
                  className="collapsible-newChart"
                >
                  {Object.entries(selectedMonthData).map(
                    ([stageName, stageValue]) => (stageName !== "Overall" ? (
                      <Panel
                        header={(
                          <PanelHeader
                            stageName={stageName}
                            stageValue={`${stageValue}%`}
                          />
                          )}
                        key={stageName}
                        style={{
                          ...getPanelStyle(stageName),
                          marginBottom: 12,
                        }}
                      >
                        <div>
                          {(() => {
                            const indicators = Object.values(
                              processedData
                                .filter(
                                  ({ month }) => months.indexOf(month)
                                      <= months.indexOf(selectedDate),
                                )
                                .reduce((acc, { data }) => {
                                  data.forEach((item) => {
                                    if (
                                      !acc[item.index]
                                        || new Date(item.updated_at)
                                          > new Date(acc[item.index].updated_at)
                                    ) {
                                      acc[item.index] = item
                                    }
                                  })
                                  return acc
                                }, {}),
                            ).filter(
                              (indicator) => indicator.stage === stageName,
                            )
                            console.log(
                              "Filtered and Reduced Indicators:",
                              indicators,
                            )
                            const stageKeys = Object.keys(countStages)
                            let cumulativeStart = 1
                            const indexRanges = stageKeys.reduce(
                              (acc, stage) => {
                                const end = cumulativeStart + countStages[stage] - 1
                                acc[stage] = {
                                  start: cumulativeStart,
                                  end,
                                }
                                cumulativeStart = end + 1
                                return acc
                              },
                              {},
                            )

                            const indexRange = indexRanges[stageName]

                            if (!indexRange) {
                              return <div>{commonStrings.noScoreYet}</div>
                            }

                            const displayIndicators = Array.from(
                              {
                                length: indexRange.end - indexRange.start + 1,
                              },
                              (_, index) => {
                                const i = index + indexRange.start
                                const indicator = indicators.find(
                                  (ind) => ind.index === i,
                                )
                                // console.log("indicator",indicator)
                                if (
                                  indicator
                                    && indicator.rating !== null

                                // This condition is commented because the
                                // client want to see those ratings as well
                                // who's explanation is null
                                // &&
                                // indicator.explanation !== null
                                ) {
                                  const month = indicator.force_updated_at
                                    ? new Date(
                                      indicator.created_at,
                                    ).toLocaleString("default", {
                                      month: "long",
                                    })
                                    : new Date(
                                      indicator.updated_at,
                                    ).toLocaleString("default", {
                                      month: "long",
                                    })

                                  return (
                                    <div>
                                      <div
                                        key={indicator.id}
                                        style={{
                                          fontWeight:
                                              month === selectedDate
                                                ? "bold"
                                                : "normal",
                                        }}
                                      >
                                        {commonStrings.indicator}{" "}
                                        {indicator.index}:{" "}
                                        {commonStrings.rating}:{" "}
                                        <span>{indicator.rating}</span>
                                      </div>
                                      {indicator.explanation === null && (
                                      <div>
                                        <span
                                          style={{
                                            fontStyle: "italic",
                                            color: "grey",
                                          }}
                                        >
                                          {
                                                commonStrings.noEvidenceSubmitted
                                              }
                                        </span>
                                      </div>
                                      )}
                                    </div>
                                  )
                                }
                                return (
                                  <div key={`missing-${i}`}>
                                    {commonStrings.indicator} {i}: No Score
                                    yet
                                  </div>
                                )
                              },
                            ).filter(Boolean) // Remove null values from the array

                            if (
                              indicators.every(
                                (indicator) => indicator.rating === null,
                              )
                                && stageValue === 0
                            ) {
                              return <div>{commonStrings.noScoreYet}</div>
                            }

                            return displayIndicators
                          })()}
                        </div>
                      </Panel>
                    ) : null),
                  )}
                </Collapse>
              </>
            ) : (
              <div className="drawer-old-graph">
                <DrawerOldRecords
                  stages={stages}
                  rubricData={rubric.old_graph_data}
                  open={isDrawerVisible}
                  closeDrawer={closeDrawer}
                />
              </div>
            )}
          </Drawer>
        </div>
        {_.first(rubric.report, 4).map((stage, index) => (
          <div key={index} id={`pdf${index}`}>
            <div
              id={`report-section-${index}`}
              className="container paddingHeader"
              style={{ backgroundColor: generalColor[index].generalColor }}
            >
              <div className="d-flex align-items-center div-collapse">
                <div className="title-collapse">
                  <span>{stage.label}</span>
                </div>

                <div className="subHeading-collapse">
                  <span>
                    <span className="partSubHeading">
                      {rubric.template.stages[index].heading}
                    </span>{" "}
                    - <span>{rubric.template.stages[index].description}</span>
                  </span>
                </div>
                <div className="progress-collapse">
                  {stage.completion < 0 ? (
                    <span className="incomplete-text">
                      {commonStrings.inCompleteText}
                    </span>
                  ) : (
                    <div style={{ width: 55, height: 55, textAlign: "center" }}>
                      <CircularProgressbar
                        text={`${stage.completion}%`}
                        value={stage.completion}
                        strokeWidth={15}
                        styles={buildStyles({
                          textColor: "white",
                          pathColor: "white",
                          trailColor: "#ffffff33",
                          textAlign: "center",
                        })}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div style={{ marginBottom: "100px" }}>
              <div className="stages">
                <span
                  style={{
                    borderColor: generalColor[index].generalColor,
                    color: generalColor[index].generalColor,
                  }}
                  className="text-uppercase text-border"
                >
                  {stage.rank}
                </span>
              </div>
              <div className="narrative">
                <span>{stage.narrative}</span>
              </div>
              <div className="d-flex justify-content-center mt40">
                <div className="d-flex blockArea">
                  <div
                    className="areas areasRightBorder"
                    style={{
                      borderColor: generalColor[index].generalColor,
                    }}
                  >
                    <span
                      style={{
                        color: generalColor[index].generalColor,
                        borderColor: generalColor[index].generalColor,
                      }}
                    >
                      {commonStrings.areasOfStrength}
                    </span>
                  </div>
                  <div
                    className="areas areasLeftBorder"
                    style={{
                      borderColor: generalColor[index].generalColor,
                    }}
                  >
                    <span
                      style={{
                        color: generalColor[index].generalColor,
                        borderColor: generalColor[index].generalColor,
                      }}
                    >
                      {commonStrings.areasOfNeed}
                    </span>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <div className="d-flex blockArea">
                  <div
                    className="areasText areasRightBorder areaOfStrength"
                    style={{
                      borderColor: generalColor[index].generalColor,
                    }}
                  >
                    {stage.areas_of_strength.length > 0 && (
                      <ul className="paddingTop40 padding-right">
                        {stage.areas_of_strength.map((area, i) => (
                          <li
                            className="listingAreaOfStrength"
                            key={i}
                            dangerouslySetInnerHTML={area}
                          />
                        ))}
                      </ul>
                    )}
                  </div>
                  <div
                    className="areasText areasLeftBorder areaOfNeeds"
                    style={{
                      borderColor: generalColor[index].generalColor,
                    }}
                  >
                    {stage.areas_of_need.length > 0 && (
                      <ul className="paddingTop40">
                        {stage.areas_of_need.map((area, i) => (
                          <li
                            className="listingAreaOfNeeds"
                            key={i}
                            dangerouslySetInnerHTML={area}
                          />
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        <div />
      </div>
    </div>
  )
}

export default ImplementationReport
