import React, { useState, useEffect } from "react"
import { UpOutlined, UpCircleOutlined } from "@ant-design/icons"
import {
  Collapse, Col, Space, Row, Tooltip, Button, Modal,
} from "antd"
import _ from "underscore"
import ScrollToTop from "react-scroll-to-top"
import { PDFDownloadLink } from "@react-pdf/renderer"
import ProgramMatrixFilters from "./Filters"
import commonStrings from "../../commonStrings"
import SELScanpdf from "./SELScanpdf"
import pdfSvg from "../../../assets/images/pdf-file.svg"

import { formatTimestamp } from "../../utilities"

import dottedLoader from "../../../assets/images/dottedloader.gif"

const { Panel } = Collapse
function ProgramMatrixCollapsible({
  data,
  matrix,
  colors,
  id,
  isDessaReport,
  currentSchool,
}) {
  const [filters, setFilters] = useState({
    categories: [],
    tiers_of_support: [],
    activities: [],
    dessa_competencies: [],
    internal_selected_grades: [],
  })

  const [studentItems, setStudentItems] = useState([])
  const [adultItems, setAdultItems] = useState([])
  const [pdfTrigger, setPdfTrigger] = useState(false)
  const [dessaCompetencies, setDessaCompetencies] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false)
  const [selectedDessaFilters, setselectedDessaFilters] = useState("")
  const selStandardGroups = data
  const dateForReport = currentSchool && currentSchool.updated_at
    ? formatTimestamp(currentSchool.updated_at)
    : null
  const currentschoolLogo = currentSchool && currentSchool.logo && currentSchool.logo.url
    ? currentSchool.logo.url
    : null
  const matchingItems = (key, type) => {
    let items = _.filter(
      matrix.completed_items,
      (item) => item.adult === (type === "adult")
        && _.contains(_.keys(item.sel_standards), key),
    )

    if (filters.categories.length) {
      items = _.filter(items, (item) => _.contains(filters.categories, item.category))
    }
    if (filters.tiers_of_support.length) {
      items = _.filter(items, (item) => _.contains(filters.tiers_of_support, item.tier_of_support))
    }
    if (filters.internal_selected_grades.length) {
      items = _.filter(items, (item) => _.some(item.internal_selected_grades, (gradeObj) => _.contains(filters.internal_selected_grades, gradeObj.grade)))
    }

    if (filters.activities.length) {
      items = _.filter(items, (item) => _.contains(filters.activities, item.activity))
    }

    return items
  }

  const itemDetail = (item) => [
    "category",

    "internal_selected_grades",
    "tier_of_support",
    "length_of_time",
    "dessa_competencies",
  ]
    .map((attr, index) => (item[attr] ? (
      <span
        key={index}
        className={
              attr === "tier_of_support" ? item[attr].toLowerCase() : null
            }
      >
        {item[attr]}
      </span>
    ) : null))
    .filter((el) => el != null)
    .reduce((prev, curr) => [prev, ", ", curr])

  const boldFirstWord = (string) => string
    .split(" ")
    .map((s, i) => (i === 0 ? <b>{s}</b> : <span>{s}</span>))
    .reduce((prev, curr) => [prev, " ", curr])

  const renderStandard = (key, standard) => (
    <div key={key} className="mb-4">
      <h5 className="mb-1">
        {key}. {boldFirstWord(standard.student_indicator_language)}
      </h5>
      <ul className="mb-3 ml-5">
        {matchingItems(key, "student").map((item, index) => (
          <li key={index}>
            {item.activity} ({itemDetail(item)})
          </li>
        ))}
      </ul>
      <h5 className="d-flex mb-1">
        <span style={{ visibility: "hidden" }}>{key}.&nbsp;</span>
        <span>{boldFirstWord(standard.adult_indicator_language)}</span>
      </h5>
      <ul className="mb-3 ml-5">
        {matchingItems(key, "adult").map((item, index) => (
          <li key={index}>
            {item.activity} ({itemDetail(item)})
          </li>
        ))}
      </ul>
    </div>
  )
  const [filtersAppliedtoall, setFiltersAppliedtoall] = useState(false)

  useEffect(() => {
    const isAnyFilterApplied = Object.values(filters).some(
      (filterArray) => filterArray.length > 0,
    )
    setFiltersAppliedtoall(isAnyFilterApplied)
  }, [filters])

  const [filtersexceptdessa, setFiltersAppliedtofilters] = useState(false)

  useEffect(() => {
    const isAnyFilterApplied = Object.entries(filters)
      .filter(([key]) => key !== "dessa_competencies")
      .some(([, filterArray]) => filterArray.length > 0)

    setFiltersAppliedtofilters(isAnyFilterApplied)
  }, [filters])
  const [areAllFiltersEmptyState, setAreAllFiltersEmptyState] = useState(false)
  const areAllFiltersEmpty = (filters) => Object.entries(filters)
    .filter(([key]) => key !== "dessa_competencies")
    .every(([, filter]) => filter.length === 0)
  useEffect(() => {
    const allEmpty = areAllFiltersEmpty(filters)
    setAreAllFiltersEmptyState(allEmpty)
  }, [filters])
  const selStandardGroup = (group, index) => {
    const color = colors[index % colors.length]

    const dessaCompetencies = filters.dessa_competencies
      ? _.uniq(_.flatten(filters.dessa_competencies))
      : []
    const isFilterApplied = dessaCompetencies.length > 0

    const applyFilter = (keys) => _.pick(group.standards, keys)
    const noFilter = (keys) => keys.map((key) => {
      if (dessaCompetencies.length === 0 || dessaCompetencies.includes(key)) {
        return renderStandard(key, group.standards[key])
      }

      return null
    })
    // const standards = _.compact(
    // _.keys(group.standards).map((key) => {
    // if (
    // dessaCompetencies.length === 0 ||
    // dessaCompetencies.find((k) => k === key)
    // ) {
    // return renderStandard(key, group.standards[key]);
    // }
    // return null;
    // })
    // );

    const standards = _.compact(
      isFilterApplied
        ? _.keys(applyFilter(dessaCompetencies)).map((key) => renderStandard(key, group.standards[key]))
        : noFilter(_.keys(group.standards)),
    )

    if (standards.length > 0) {
      let description = ""
      switch (group.name.trim().toLowerCase()) {
        case "self-awareness":
          description = "Recognizing who I am, what I need, and how I feel relative to the world around me."
          break
        case "self management":
          description = "Managing my behavior in pro social ways."
          break
        case "social awareness":
          description = "Demonstrating an awareness of the role and values of others in the greater community."
          break
        case "social management":
          description = "Interacting with others in meaningful and productive ways."
          break
        default:
          description = ""
          break
      }

      return (
        <div
          key={index}
          className="collapsee"
          id={id}
          style={{ padding: "9px" }}
        >
          <Collapse
            key={index}
            style={{
              background: color,
              color: "white",
            }}
            expandIcon={({ isActive }) => (
              <UpOutlined
                style={{
                  fontSize: "25px",
                  color: "white",
                  cursor: "pointer",
                }}
                rotate={isActive ? 0 : 180}
              />
            )}
            expandIconPosition="end"
            collapsible="header"
          >
            <Panel
              header={(
                <div className="new-collapsible-div overall-collapsible">
                  <div className="c-ttile">
                    <div className="new-collapsible-title-SELScan">
                      {group.name}
                    </div>
                  </div>
                  {isDessaReport && (
                    <div className="sel-description">{description}</div>
                  )}
                </div>
              )}
              key={id}
            >
              <div className="inner-contaier-body">
                {Object.keys(group.standards)
                  .filter(
                    (key) => dessaCompetencies.length === 0
                      || dessaCompetencies.includes(key),
                  )
                  .map((key) => {
                    const studentItems = matchingItems(key, "student")
                    const adultItems = matchingItems(key, "adult")

                    const hasCompetencies = studentItems.length > 0
                    const hasAdultCompetencies = adultItems.length > 0

                    return (
                      <div className="sel-inner" key={key}>
                        {group.standards ? (
                          <div className="students_in_sel">
                            {areAllFiltersEmpty(filters) ? (
                              <div
                                className="all-data"
                                style={{
                                  fontWeight: hasCompetencies ? "700" : "400",
                                  backgroundColor:
                                    hasCompetencies || hasAdultCompetencies
                                      ? `rgba(${parseInt(
                                        color.slice(1, 3),
                                        16,
                                      )}, ${parseInt(
                                        color.slice(3, 5),
                                        16,
                                      )}, ${parseInt(
                                        color.slice(5, 7),
                                        16,
                                      )}, 0.1)`
                                      : "transparent",
                                }}
                              >
                                <div className="student-key">
                                  <span style={{ color }}>{key}</span>
                                </div>

                                <div className="inside-data">
                                  <div className="student-key-data">
                                    {
                                      group.standards[key]
                                        .student_indicator_language
                                    }
                                  </div>

                                  {hasCompetencies && (
                                    <div className="competencies">
                                      <ul>
                                        {studentItems.map((item, index) => (
                                          <li key={index}>
                                            {item.activity} ({item.category},{" "}
                                            {item.tier_of_support},{" "}
                                            {item.length_of_time}
                                            {Array.isArray(
                                              item.internal_selected_grades,
                                            )
                                              && item.internal_selected_grades
                                                .length > 0
                                              && ", Grades: "}
                                            {item.internal_selected_grades
                                              && Array.isArray(
                                                item.internal_selected_grades,
                                              )
                                              && item.internal_selected_grades
                                                .length > 0
                                              && item.internal_selected_grades.map(
                                                (grade, idx) => (
                                                  <span key={idx}>
                                                    {grade.grade}
                                                    {idx
                                                    < item
                                                      .internal_selected_grades
                                                      .length
                                                      - 1
                                                      ? ", "
                                                      : ""}
                                                  </span>
                                                ),
                                              )}
                                            )
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  )}

                                  <div
                                    className="adults-sel"
                                    style={{
                                      fontWeight: hasAdultCompetencies
                                        ? "700"
                                        : "400",
                                    }}
                                  >
                                    <span>
                                      {
                                        group.standards[key]
                                          .adult_indicator_language
                                      }
                                    </span>

                                    {adultItems.some(
                                      (item) => item.category === "Adult SEL",
                                    ) && (
                                      <div className="competencies">
                                        <ul>
                                          {adultItems.map((item, index) => (
                                            <li key={index}>
                                              {item.activity} ({item.category},{" "}
                                              {item.tier_of_support},{" "}
                                              {item.length_of_time})
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ) : hasCompetencies || hasAdultCompetencies ? (
                              <div
                                className="all-data"
                                style={{
                                  fontWeight: hasCompetencies ? "700" : "400",
                                  backgroundColor:
                                    hasCompetencies || hasAdultCompetencies
                                      ? `rgba(${parseInt(
                                        color.slice(1, 3),
                                        16,
                                      )}, ${parseInt(
                                        color.slice(3, 5),
                                        16,
                                      )}, ${parseInt(
                                        color.slice(5, 7),
                                        16,
                                      )}, 0.1)`
                                      : "transparent",
                                }}
                              >
                                <div className="student-key">
                                  <span style={{ color }}>{key}</span>
                                </div>

                                <div className="inside-data">
                                  <div className="student-key-data">
                                    {
                                      group.standards[key]
                                        .student_indicator_language
                                    }
                                  </div>

                                  {hasCompetencies && (
                                    <div className="competencies">
                                      <ul>
                                        {studentItems.map((item, index) => (
                                          <li key={index}>
                                            {item.activity} ({item.category},{" "}
                                            {item.tier_of_support},{" "}
                                            {item.length_of_time}
                                            {Array.isArray(
                                              item.internal_selected_grades,
                                            )
                                              && item.internal_selected_grades
                                                .length > 0
                                              && ", Grades: "}
                                            {item.internal_selected_grades
                                              && Array.isArray(
                                                item.internal_selected_grades,
                                              )
                                              && item.internal_selected_grades
                                                .length > 0
                                              && item.internal_selected_grades.map(
                                                (grade, idx) => (
                                                  <span key={idx}>
                                                    {grade.grade}
                                                    {idx
                                                    < item
                                                      .internal_selected_grades
                                                      .length
                                                      - 1
                                                      ? ", "
                                                      : ""}
                                                  </span>
                                                ),
                                              )}
                                            )
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  )}

                                  <div
                                    className="adults-sel"
                                    style={{
                                      fontWeight: hasAdultCompetencies
                                        ? "700"
                                        : "400",
                                    }}
                                  >
                                    <span>
                                      {
                                        group.standards[key]
                                          .adult_indicator_language
                                      }
                                    </span>

                                    {adultItems.some(
                                      (item) => item.category === "Adult SEL",
                                    ) && (
                                      <div className="competencies">
                                        <ul>
                                          {adultItems.map((item, index) => (
                                            <li key={index}>
                                              {item.activity} ({item.category},{" "}
                                              {item.tier_of_support},{" "}
                                              {item.length_of_time})
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        ) : null}
                      </div>
                    )
                  })}
                {filtersAppliedtoall
                  ? Object.keys(group.standards)
                    .filter(
                      (key) => dessaCompetencies.length === 0
                          || dessaCompetencies.includes(key),
                    )
                    .every((key) => {
                      const studentItems = matchingItems(key, "student")
                      const adultItems = matchingItems(key, "adult")

                      return (
                        studentItems.length === 0 && adultItems.length === 0
                      )
                    })
                    && filtersexceptdessa && (
                      <div className="no-activity-message">
                        <span>{commonStrings.NoDataExists}</span>
                      </div>
                  )
                  : filtersAppliedtoall
                    && filtersexceptdessa && (
                      <div className="no-activity-messages">
                        <span>{commonStrings.NoDataExists}</span>
                      </div>
                  )}
              </div>
              <div>
                <ScrollToTop
                  style={{
                    border: "1px solid #7E7C7D",
                    marginBottom: "50px",
                    boxShadow: "none",
                  }}
                  component={(
                    <Tooltip
                      placement="top"
                      title={(
                        <span style={{ fontWeight: "bolder" }}>
                          {commonStrings.scrollerText}
                        </span>
                      )}
                    >
                      <div className="animate__animated animate__bounceIn scrollerToTop">
                        <UpCircleOutlined />
                      </div>
                    </Tooltip>
                  )}
                  smooth
                />
              </div>
            </Panel>
          </Collapse>
        </div>
      )
    }

    return null
  }

  useEffect(() => {
    setDessaCompetencies(
      filters.dessa_competencies
        ? _.uniq(_.flatten(filters.dessa_competencies))
        : [],
    )
  }, [filters.dessa_competencies])

  useEffect(() => {
    if (!pdfTrigger) return

    const hasFilters = Object.values(filters).some((array) => array.length > 0)
    const isFilterApplied = dessaCompetencies.length > 0

    const extractStudentAdultItems = (group) => {
      const keys = Object.keys(group.standards)
      const filteredKeys = isFilterApplied
        ? keys.filter((key) => dessaCompetencies.includes(key))
        : keys

      const studentItems = _.uniq(
        filteredKeys.flatMap((key) => matchingItems(key, "student")),
      )

      const adultItems = _.uniq(
        filteredKeys.flatMap((key) => matchingItems(key, "adult")),
      )

      return { studentItems, adultItems }
    }

    const updateItems = async () => {
      try {
        const aggregatedStudentItems = _.uniq(
          selStandardGroups.flatMap(
            (group) => extractStudentAdultItems(group).studentItems,
          ),
        )

        const aggregatedAdultItems = _.uniq(
          selStandardGroups.flatMap(
            (group) => extractStudentAdultItems(group).adultItems,
          ),
        )

        if (hasFilters) {
          setStudentItems(aggregatedStudentItems)
          setAdultItems(aggregatedAdultItems)
        } else {
          setStudentItems(
            _.filter(matrix.completed_items, (item) => !item.adult),
          )
          setAdultItems(_.filter(matrix.completed_items, (item) => item.adult))
        }
      } catch (error) {
        console.error("Error updating items:", error)
      }
    }

    updateItems().finally(() => {
      setPdfTrigger(false)
    })
  }, [pdfTrigger, filters])

  useEffect(() => {
    setPdfTrigger(false)
  }, [filters])

  const showModal = () => {
    setIsModalVisible(true)
    setIsGeneratingPDF(true)

    setTimeout(() => {
      setPdfTrigger(true)
      setIsGeneratingPDF(false)
    }, 3000)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    setIsGeneratingPDF(false)
  }
  const getSelectedFilterValues = () => {
    const filterNamesMap = {
      categories: "Domains",
      tiers_of_support: "Tiers of Support",
      internal_selected_grades: "Grade Levels",
      dessa_competencies: "Dessa Competencies",
      activities: "Activities",
    }

    const selectedFilters = Object.keys(filterNamesMap).reduce((acc, key) => {
      if (filters[key] && filters[key].length) {
        acc.push({
          name: filterNamesMap[key],
          values: filters[key],
        })
      }

      return acc
    }, [])

    return selectedFilters.length > 0
      ? selectedFilters
      : [{ name: "No filters selected", values: [] }]
  }

  const onDessaFilterChange = (selectedDessaFilter) => {
    setselectedDessaFilters(selectedDessaFilter || [])
  }
  const filtersApplied = Object.values(filters).some(
    (filterArray) => filterArray.length > 0,
  )
  return (
    <div>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Space
            direction="vertical"
            size={0}
            style={{ display: "flex", backgroundColor: "white" }}
          >
            <div className="pdf-filters-container">
              <div className="pdf-activity-details">
                <button onClick={showModal} className="open-modal-button">
                  <img
                    src={pdfSvg}
                    alt="Open modal"
                    className="pdf-icon-button"
                  />
                </button>

                <Modal
                  title={null}
                  visible={isModalVisible}
                  onCancel={handleCancel}
                  footer={null}
                  centered
                  bodyStyle={{ textAlign: "center", padding: "30px" }}
                  className="activity-details-modal"
                >
                  {isGeneratingPDF ? (
                    <div className="pdf-generation-container">
                      <div className="pdf-loader-section">
                        <img
                          src={dottedLoader}
                          alt="Loading..."
                          className="pdf-loader-icon"
                        />
                        <span className="pdf-preparing-text">
                          {commonStrings.pdfPreparing}
                        </span>
                        <span className="pdf-waiting-text">
                          {commonStrings.waiting}
                        </span>
                        <div className="pdf-action-buttons">
                          <Button
                            onClick={handleCancel}
                            className="cancel-button"
                          >
                            {commonStrings.cancel}
                          </Button>
                          <Button disabled className="download-button disabled">
                            {commonStrings.download}
                          </Button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="pdf-ready-container">
                      <div className="pdf-info-section">
                        <img src={pdfSvg} alt="PDF Icon" className="pdf-icon" />
                        <span className="pdf-ready-text">
                          {filtersApplied
                            ? commonStrings.pdfReady
                            : "PDF is ready to download"}
                        </span>
                        {filtersApplied && (
                          <div className="selected-filters-list">
                            {getSelectedFilterValues().map((filter, index) => (
                              <div key={index} className="filter-item">
                                <span className="filter-name">
                                  {filter.name}:
                                </span>{" "}
                                <span className="filter-values">
                                  {filter.name === "Dessa Competencies"
                                    ? selectedDessaFilters.length
                                      ? selectedDessaFilters.join(", ")
                                      : "No competencies selected"
                                    : filter.values.join(", ")}{" "}
                                </span>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                      <div className="pdf-action-buttons">
                        <Button
                          onClick={handleCancel}
                          className="cancel-button"
                        >
                          {commonStrings.cancel}
                        </Button>
                        <PDFDownloadLink
                          document={(
                            <SELScanpdf
                              data={
                                isDessaReport
                                  ? window.gon.SEL_STANDARDS
                                  : window.gon.DESS_SEL_STANDARDS
                              }
                              aggregatedStudentItems={studentItems}
                              aggregatedAdultItems={adultItems}
                              currentschoolLogo={currentschoolLogo}
                              dessaCompetencies={dessaCompetencies}
                              filtersAppliedtoall={filtersAppliedtoall}
                              filtersexceptdessa={filtersexceptdessa}
                              areAllFiltersEmptyState={areAllFiltersEmptyState}
                              filters={filters}
                              selectedDessaFilters={selectedDessaFilters}
                            />
                          )}
                          fileName={`${
                            currentSchool.name || "school_name"
                          }_report(${dateForReport}).pdf`}
                        >
                          {({ loading }) => (
                            <Button className="download-button">
                              {loading ? "Loading..." : "Download"}
                            </Button>
                          )}
                        </PDFDownloadLink>
                      </div>
                    </div>
                  )}
                </Modal>
              </div>
              <div className="filters-container">
                <ProgramMatrixFilters
                  filters={filters}
                  setFilters={setFilters}
                  activities={_.pluck(matrix.completed_items, "activity")}
                  isDessaReport={isDessaReport}
                  onDessaFilterChange={onDessaFilterChange}
                />
              </div>
            </div>

            {selStandardGroups.map((group, index) => selStandardGroup(group, index))}
          </Space>
        </Col>
      </Row>
    </div>
  )
}

export default ProgramMatrixCollapsible
